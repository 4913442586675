import React from 'react';
import { Avatar, Card, Carousel } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';


const TestimonialSection = () => {
    const feedbacks = [
        {
            image: '/assets/images/client1.png',
            name: 'Jordan Macintyre',
            address: 'New Zealand',
            feedback: 'Very detailed and did exactly what I asked. Very helpful and very good communication. Recommend SDN anyday.'
        },
        {
            image: '/assets/images/client2.png',
            name: 'Mr. Poydras',
            address: 'United States',
            feedback: 'Seller was quick and courteous Provided vivid detail to our program flyer. Excellent choice for digital graphic design resources'
        },
        {
            image: '/assets/images/client3.png',
            name: 'JSuzanne Wolf',
            address: 'United States',
            feedback: 'I have always had such perfect results and I appreciate how quickly he gets everything done for me! Thank you so much again!'
        },
        {
            image: '/assets/images/client1.png',
            name: 'Jordan Macintyre',
            address: 'New Zealand',
            feedback: 'Very detailed and did exactly what I asked. Very helpful and very good communication. Recommend SDN anyday.'
        },
        {
            image: '/assets/images/client1.png',
            name: 'Jordan Macintyre',
            address: 'New Zealand',
            feedback: 'Very detailed and did exactly what I asked. Very helpful and very good communication. Recommend SDN anyday.'
        },
    ];

    // Custom Arrow Components
    const PrevArrow = ({ onClick }) => (
        <div className="gx-carousel-arrow gx-carousel-arrow-left" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30px', height: '30px', border: '1px solid #fff', borderRadius: '50%' }} onClick={onClick}>
            <ArrowLeftOutlined style={{ fontSize: '16px', fontWeight: '400', color: '#fff' }} />
        </div>
    );

    const NextArrow = ({ onClick }) => (
        <div className="gx-carousel-arrow gx-carousel-arrow-right" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30px', height: '30px', border: '1px solid #fff', borderRadius: '50%' }} onClick={onClick}>
            <ArrowRightOutlined style={{ fontSize: '16px', fontWeight: '400', color: '#fff' }} />
        </div>
    );

    const carouselSettings = {
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 4, // Default for extra-large devices
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1440, // For laptops and larger screens
                settings: { slidesToShow: 3 }, // Show 3 slides on laptops
            },
            {
                breakpoint: 1024, // For tablets
                settings: { slidesToShow: 3 },
            },
            {
                breakpoint: 720, // For medium-sized devices
                settings: { slidesToShow: 2 },
            },
            {
                breakpoint: 480, // For mobile devices
                settings: { slidesToShow: 1 },
            },
        ],
    };

    return (
        <div className="gx-testimonial-section gx-section gx-bg-dark">
            <div className="gx-container">
                <div className="gx-section-title-wrapper gx-text-center gx-mb-5">
                    <span className='gx-section-child-title'>Clients Testimonials</span>
                    <h4 className="gx-section-subtitle">
                        I’ve 1274+
                    </h4>
                    <h1 className="gx-section-title">
                        Clients Feedback
                    </h1>
                </div>
                <Carousel {...carouselSettings} className="gx-testimonial-carousel">
                    {feedbacks.map((feedback, index) => (
                        <div key={index} className="gx-testimonial-item" style={{ padding: '0 15px' }}>
                            <Card
                                className='gx-testimonial-card'
                                style={{
                                    width: 300,
                                    // minHeight: 360,
                                    textAlign: 'center',
                                    backgroundColor: '#1c1c1c',
                                    color: '#fff',
                                    border: '1px solid #333',
                                    borderRadius: '10px',
                                    padding: '20px 20px 8px 20px',
                                }}
                                bordered={false}
                            >
                                <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', minHeight: '300px'}}>
                                    <div>
                                        <div style={{ position: 'relative', marginBottom: '16px' }}>
                                            <Avatar
                                                size={80}
                                                src={feedback.image} // Replace with the actual image path
                                                style={{ marginBottom: '8px' }}
                                                loading= "lazy"
                                            />
                                            <div className='gx-feedback-quote'

                                            >
                                                <h1 className='gx-mb-0 gx-mt-2'>“</h1>
                                            </div>
                                        </div>
                                        <p className='gx-client-feedback'>
                                            {
                                                feedback.feedback
                                            }
                                        </p>
                                    </div>
                                    <div>
                                        <h2 className='gx-client-name'>
                                            {feedback.name}
                                        </h2>
                                        <p className='gx-client-address'>
                                            {feedback.address}
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default TestimonialSection;
