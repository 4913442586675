import { set, get, unset } from "lodash-es";
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_PHONE_USER_SUCCESS,
  SIGNIN_PHONE_USER_SMS_CODE,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  SET_ACTIVE_CHAT_KEY,
  LOCAL_DB,
  UPDATE_IN_LOCAL_DB,
  REMOVE_IN_LOCAL_DB,
  UPDATE_MOD_TEACHER_IN_LOCAL_DB,
  UPDATE_STUDENT_IN_LOCAL_DB,
  UPDATE_ADMIN_TEACHER_IN_LOCAL_DB,
  TEST_DB,
  ADD_PROGRESS_OBJECT,
  UPDATE_PROGRESS_OBJECT,
  Remove_All_PROGRESS_OBJECT,
  Remove_PROGRESS_OBJECT_BY_ID,
  SET_ACTIVE_TEACHER_UID,
  SET_ALL_TEACHERS_UID,
  SET_IS_LIVE_TYPING_ENABLED
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loader                : false,
  alertMessage          : "",
  showMessage           : false,
  initURL               : "",
  progressData          : [],
  authUser              : localStorage.getItem("user_id"),
  db                    : undefined
};

const AuthReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_PHONE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_PHONE_USER_SMS_CODE: {
      return {
        ...state,
        loader: false,
        confirmation: action.payload,
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case LOCAL_DB: {
      return {
        ...state,
        db: action.payload,
      };
    }
    case UPDATE_IN_LOCAL_DB: {

      let { objToAdd } = action.payload;

      return {
        ...state,
        db: objToAdd,
      };
    }

    case SET_ACTIVE_TEACHER_UID: {

      return {
        ...state,
        activeTeacherUID: action.payload.uid,
      };
    }

    case SET_ACTIVE_CHAT_KEY: {

        return {
          ...state,
          activeChatKey: action.payload.key,
        };
      }

      case SET_IS_LIVE_TYPING_ENABLED: {
        return {
          ...state,
          isLiveTypingEnabled: action.payload.bool,
        };
    }

    case SET_ALL_TEACHERS_UID: {

      return {
        ...state,
        allTeachersUID: action.payload.allUid,
      };
    }

    case UPDATE_MOD_TEACHER_IN_LOCAL_DB: {


      let { objToAdd } = action.payload;

      let currentDB = { ...state['db'] };
      let existingData = get(currentDB, ['VolatileLocalData', 'Moderating'], {});


      set(currentDB, ['VolatileLocalData', 'Moderating'], { ...existingData, ...objToAdd });

      return {
        ...state,
        db: currentDB,
      };
    }
    case UPDATE_STUDENT_IN_LOCAL_DB: {

        let { objToAdd } = action.payload;
  
        let currentDB = { ...state['db'] };
        let existingData = get(currentDB, ['VolatileLocalData', 'TaughtBy'], {});
  
  
        set(currentDB, ['VolatileLocalData', 'TaughtBy'], { ...existingData, ...objToAdd });
  
        return {
          ...state,
          db: currentDB,
        };
      }
    case UPDATE_ADMIN_TEACHER_IN_LOCAL_DB: {

      let { objToAdd } = action.payload;

      let currentDB = { ...state['db'] };

      let existingData = get(currentDB, ['VolatileLocalData', 'Admined'], {});


      set(currentDB, ['VolatileLocalData', 'Admined'], { ...existingData, ...objToAdd });


      return {
        ...state,
        db: currentDB,
      };
    }
    case REMOVE_IN_LOCAL_DB: {

      let { pathArr } = action.payload;

      let currentDB = Object.create(state.db);
      unset(currentDB, pathArr);

      return {
        ...state,
        db: currentDB,
      };
    }
    case TEST_DB: {
      return {
        ...state,
        test: action.payload,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: "/welcome",
        loader: false,
        db: undefined
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }

    //ProcessBar reducer

    case ADD_PROGRESS_OBJECT: {
      let currentProgressObj = state.progressData;
      currentProgressObj.push(action.payload);
      state.progressData = currentProgressObj;
      return {
        ...state,
        progress: action.payload,
      };
    }
    case UPDATE_PROGRESS_OBJECT: {
      let currentProgressObj = state.progressData;
      let { index, percent } = action.payload;

      currentProgressObj[index]["percentage"] = percent;
      state.progressData = currentProgressObj;

      return {
        ...state,
        progress: action.payload,
      };

    }

    case Remove_All_PROGRESS_OBJECT: {
      return {
        ...state,
        progressData: [],
      };
    }

    case Remove_PROGRESS_OBJECT_BY_ID: {
      let currentProgressObj = state.progressData;
      let { index } = action.payload;

      currentProgressObj.splice(index, 1);
      state.progressData = currentProgressObj;

      return {
        ...state,
        progress: action.payload,
      };
    }

    //Ends here

    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
      };
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;