import mixpanel from 'mixpanel-browser';
import firebase from 'firebase';

let analytics = firebase.analytics();

class Mixpanel {

    init(){
        // mixpanel.init('af68304db0f55479b88b986a8816cdb9', {debug: true}); 
    }

    setUserData({uid, email, name}){
        // mixpanel.register({uid, 'Email': email, name, email, 'Name': name});
        // mixpanel.identify(uid);
        // mixpanel.people.set({uid, 'Email': email, email, name, 'Name': name});

        // analytics.setUserId(uid);
        // analytics.setUserProperties({uid: uid});
        // analytics.setUserProperties({email: email});
        // analytics.setUserProperties({name: name});
    }

    record({eventName, eventProperties}){
        // try{
        //     mixpanel.track(eventName, eventProperties, {send_immediately: true});
        //     analytics.logEvent(eventName, eventProperties);
        // }
        // catch(e){
        //     console.log(e);
        // }
    }

}


export default new Mixpanel()