import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Input, Row } from 'antd';
import { Link } from 'react-router-dom';
import ServicesSection from './ServiceSection';
import PortfolioSection from './PortfolioSection';
import SkillsSection from './SkillsSection';
import TestimonialSection from './TestimonialSection';
import ContactSection from './ContactSection';
import Footer from './Footer';
import CountUp from 'react-countup';


const HomePage = () => {

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  const handleEmailClick = () => {
    window.open(
      "https://mail.google.com/mail/?view=cm&to=hello@sdnoronyo.com",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const openMobileMenu = () => {
    console.log('clicked',)
    setIsOpenMenu(prev => !prev)
  }

  const clickMenuLink = () => {
    setIsOpenMenu(false)
  }

  const [visible, setVisible] = useState(false)
  const contactSectionRef = useRef(null); // Ref for the Contact section
  const portfolioSectionRef = useRef(null); // Ref for the Contact section
  const homeSectionRef = useRef(null); // Ref for the Contact section
  const serviceSectionRef = useRef(null); // Ref for the Contact section

  const handleDownload = () => {
    // Direct download link for the PDF
    const pdfUrl = "https://drive.google.com/uc?export=download&id=1xDX5RMrM-Z1A-xUxngf7no0o5A98eQWt"; // Replace YOUR_FILE_ID with the actual ID
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Resume.pdf"; // The name for the downloaded file
    link.click();
  };

  const scrollToContact = () => {
    if (contactSectionRef.current) {
      const offsetTop = contactSectionRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: offsetTop - 70, // Adjust for the desired offset (100px above the section)
        behavior: 'smooth',
      });
    }
  };

  const scrollToService = () => {
    if (serviceSectionRef.current) {
      const offsetTop = serviceSectionRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: offsetTop - 70, // Adjust for the desired offset (100px above the section)
        behavior: 'smooth',
      });
    }
  };

  const scrollToPortfolio = () => {
    if (portfolioSectionRef.current) {
      const offsetTop = portfolioSectionRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: offsetTop - 70, // Adjust for the desired offset (100px above the section)
        behavior: 'smooth',
      });
    }
  };

  const scrollToHome = () => {
    if (homeSectionRef.current) {
      const offsetTop = homeSectionRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: offsetTop - 90, // Adjust for the desired offset (100px above the section)
        behavior: 'smooth',
      });
    }
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };



  useEffect(() => {
    // Close Navbar Menu on Click Outside
    window.addEventListener('click', function (e) {
      console.log('e', e)
      if (!document.querySelector('#gx-menu')?.contains(e.target) && !document.querySelector('.gx-burger')?.contains(e.target)) {
        clickMenuLink()
      }
    })

    window.addEventListener('scroll', toggleVisible);


    // Fixed header after scroll 50
    window.addEventListener("scroll", (v) => {
      if (window.scrollY > 10) {
        setIsScroll(true)
      }
      else { setIsScroll(false) }
    })

    return () => { }

  }, [])


  return (
    <div className='gx-home-page'>
      <header className={isScroll ? 'gx-header on-scroll gx-bg-dark' : 'gx-header gx-bg-dark'} id="header">
        <nav className="gx-navbar gx-container">
          <Link to="/" onClick={scrollToHome} className="gx-brand" id="gx-brand">
            <img className='gx-brand-logo' alt="brand" src={("/assets/images/logo.png")} />
          </Link>
          <div className={isOpenMenu ? 'gx-burger is-active' : 'gx-burger'} id="burger" onClick={() => openMobileMenu()}>
            <span className="gx-burger-line"></span>
            <span className="gx-burger-line"></span>
            <span className="gx-burger-line"></span>
          </div>
          <div className={isOpenMenu ? 'gx-menu is-active' : 'gx-menu'} id="gx-menu">
            <ul className="gx-menu-inner">
              <li className="gx-menu-item" onClick={() => clickMenuLink()}>
                <Link to="/" onClick={scrollToHome} className="gx-menu-link">Home</Link>
              </li>
              <li className="gx-menu-item" onClick={() => clickMenuLink()}>
                <Link to="/" onClick={scrollToPortfolio} className="gx-menu-link">Portfolio</Link>
              </li>
              <li className="gx-menu-item" onClick={() => clickMenuLink()}>
                <Link to='/' onClick={scrollToContact} className="gx-menu-link">Contact</Link>
              </li>
            </ul>

            <div className="gx-menu-right-in-mobile">
              <Button onClick={() => handleEmailClick()} className='gx-mb-0 gx-btn-info'>hello@sdnoronyo.com</Button>

            </div>
          </div>

          <div className="gx-menu-right">
            <Button onClick={() => handleEmailClick()} className='gx-mb-0 gx-btn-info'>hello@sdnoronyo.com</Button>

          </div>
        </nav>
      </header>

      <main className="gx-main">
        <section className="gx-banner-section gx-section gx-bg-dark" ref={homeSectionRef}>
          <div className="gx-banner-container gx-container">
            <div className="gx-banner-content">
              <h1>
                <span role="img" aria-label="wave">👋</span> <span className='gx-title-self'>this is SDN and I am</span>
                <br />
                <span className="gx-title-bold">GRAPHIC</span> <span className="gx-title-light">and</span> <span className="gx-title-bold gx-d-block">BRAND DESIGNER</span>
              </h1>
              <div className="gx-buttons-wrapper">
                <Button onClick={scrollToContact} className="gx-home-btn gx-btn-info">Let's start a project together</Button>
                <Button onClick={() => handleDownload()} className="gx-home-btn gx-btn-outline-light">Download Resume</Button>
              </div>
            </div>

            <div className="gx-banner-stats">
              <div className="gx-banner-stat">
                <h3>

                  <CountUp
                    start={1}
                    end={7}
                    duration={3.75}
                    delay={0}
                    suffix="+"
                    startOnMount={true}
                    onEnd={() => console.log('Ended! 👏')}
                    onStart={() => console.log('Started! 💨')}
                  >
                    {({ countUpRef, start }) => (
                      <div>
                        <span className="gx-highlight" ref={countUpRef} />

                      </div>
                    )}
                  </CountUp>
                </h3>
                <p className='gx-my-0 gx-ml-2'>Years of <span className='gx-d-block'>Experience</span></p>
              </div>
              <div className="gx-banner-stat">
                <h3>

                  <CountUp
                    start={1}
                    end={5}
                    duration={3.75}
                    delay={0}
                    suffix="K+"
                    startOnMount={true}
                    onEnd={() => console.log('Ended! 👏')}
                    onStart={() => console.log('Started! 💨')}
                  >
                    {({ countUpRef, start }) => (
                      <div>
                        <span className="gx-highlight" ref={countUpRef} />

                      </div>
                    )}
                  </CountUp>
                </h3>
                <p className='gx-my-0 gx-ml-2'>Project <span className="gx-d-block">Complete</span>

                </p>
              </div>
              <div className="gx-banner-stat">
                <h3>

                  <CountUp
                    start={1}
                    end={99}
                    duration={2.75}
                    delay={0}
                    suffix="%"
                    startOnMount={true}
                    onEnd={() => console.log('Ended! 👏')}
                    onStart={() => console.log('Started! 💨')}
                  >
                    {({ countUpRef, start }) => (
                      <div>
                        <span className="gx-highlight" ref={countUpRef} />

                      </div>
                    )}
                  </CountUp>
                </h3>
                <p className='gx-my-0 gx-ml-2'>Client <span className="gx-d-block">Satisfactions</span></p>
              </div>
            </div>


          </div>

          <div className="gx-banner-image">
            <img loading='lazy' src="/assets/images/banner-image.png" alt="SDN" />
          </div>
        </section>


        <div ref={portfolioSectionRef}>
          <PortfolioSection />
        </div>

        <div ref={serviceSectionRef}>
          <ServicesSection scrollToContact={scrollToContact} />
        </div>

        <SkillsSection />

        <TestimonialSection />



        <div ref={contactSectionRef}>
          <ContactSection />
        </div>



      </main>

      <Footer scrollToService = {scrollToService} scrollToHome={scrollToHome} scrollToContact={scrollToContact} scrollToPortfolio={scrollToPortfolio} handleDownload={handleDownload} />
    </div>

  )
}

export default HomePage