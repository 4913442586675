import { Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';


const Footer = ({handleDownload, scrollToHome, scrollToPortfolio, scrollToContact, scrollToService }) => {

    return (
        <footer className="gx-footer">
            <div className="gx-container gx-footer-content-wrapper">

                <div className="gx-footer-bottom">
                    <div className="gx-footer-left" style={{width: '20%'}}>
                        <img loading='lazy' src={`/assets/images/logo.png`} alt="SDN Logo" className="gx-footer-logo" />
                        {/* <Select
                            suffixIcon={<span className="gx-custom-arrow">▼</span>}
                            className="gx-language-selector"
                            defaultValue="English"
                        >
                            <Select.Option value="English">English</Select.Option>
                            <Select.Option value="Bangla">Bangla</Select.Option>

                        </Select> */}
                    </div>

                    <div className="gx-footer-links">
                        <div className="gx-footer-column">
                            <ul>
                                <li><Link to="/">About Me</Link></li>
                                <li><Link to="/" onClick={()=> scrollToPortfolio()}>Portfolio</Link></li>
                                <li><Link to="/" onClick={()=> scrollToService()}>Services</Link></li>
                                <li><Link to="/" onClick={()=> scrollToContact()}>Contact</Link></li>
                            </ul>
                        </div>

                        <div className="gx-footer-column">
                            <ul>
                                <li><a href="https://www.behance.net/sdn_oronyo" target="_blank" rel="noopener noreferrer">Behance</a></li>
                                <li onClick={()=> handleDownload()}><Link to="/">Download Resume</Link></li>

                                <li><Link to="/" onClick={()=> scrollToContact()}>Let's Work Together</Link></li>
                                <li><Link to="/" onClick={()=> handleDownload()}>Hire Me</Link></li>
                            </ul>
                        </div>

                        <div className="gx-footer-column">
                            <ul>
                                <li><Link to="/">Privacy Policy</Link></li>
                                <li> <Link to="/">Terms of Use</Link></li>
                                <li><Link to="/">Copyright Information</Link></li>
                            </ul>
                        </div>

                        <div className="gx-footer-column">

                            <ul>
                                <li><a href="https://www.linkedin.com/in/sdnoronyo/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                                <li><a href="https://www.instagram.com/sdnoronyo/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                                <li><a href="https://x.com/sdn_oronyo" target="_blank" rel="noopener noreferrer">Twitter/X</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="gx-footer-copyright">
                    <span>© SDN</span>
                    <div>
                        <Link to="/terms-of-service">Terms of Service</Link>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </div>
                </div> */}
            </div>
        </footer>
    );
};

export default Footer;
