const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'bangla',
    locale: 'bn',
    name: 'Bangla',
    icon: 'bd'
  }

];
export default languageData;



// {
//     languageId: 'chinese',
//     locale: 'zh',
//     name: 'Chinese',
//     icon: 'cn'
//   },
//   {
//     languageId: 'spanish',
//     locale: 'es',
//     name: 'Spanish',
//     icon: 'es'
//   },

//   {
//     languageId: 'italian',
//     locale: 'it',
//     name: 'Italian',
//     icon: 'it'
//   },
//   {
//     languageId: 'saudi-arabia',
//     locale: 'ar',
//     name: 'Arabic',
//     icon: 'sa'
//   }