import { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";




const Dashboard = () => {

    const { db}      = useSelector(({ auth }) => auth);




    return (
        <>
            <div className="gx-main-content-wrapper gx-py-2" style={{backgroundColor: 'rgb(50, 58, 67)'}}>
                
         
            </div>
                                        
        </>
    );
};

export default Dashboard;
