import firebase from "firebase";

const config = {
  apiKey: "AIzaSyBwZ8kr7FPIhFc0-RHtkAEKnMlmoh5YfYs",
  authDomain: "fire-auth-d017e.firebaseapp.com",
  projectId: "fire-auth-d017e",
  storageBucket: "fire-auth-d017e.appspot.com",
  messagingSenderId: "1038263242969",
  appId: "1:1038263242969:web:7bb5ad0de0b3418c47eec8",
  measurementId: "G-3QV2T9Z2R2"
};

firebase.initializeApp(config);

const auth                          = firebase.auth();
const database                      = firebase.database();


export {
  database,
  auth
};
