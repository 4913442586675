import React, {useEffect, useState, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Drawer, Layout, notification, Button} from "antd";
import PopupNewsSlider from "../../components/PopupNewsSlider";
import SidebarContent from "./SidebarContent";
import {toggleCollapsedSideNav} from "../../appRedux/actions";
import firebase from 'firebase';
import { useHistory } from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const {Sider}       = Layout;
const database      = firebase.database();

const Sidebar = () => {

    let [sidebarCollapsed, setSidebarCollapsed]             =       useState(false);
    const {themeType, navStyle}                             =       useSelector(({settings}) => settings);
    const navCollapsed                                      =       useSelector(({common}) => common.navCollapsed);
    const width                                             =       useSelector(({common}) => common.width);
    const dispatch = useDispatch();
    const [newsShownOnce, setNewsShownOnce]                 =       useState(undefined);
    const { db, authUser }                                  =       useSelector(({ auth }) => auth);

    const [activeToolsJSON, setActiveToolsJSON]             =       useState({});
    const [activeToolsKEY, setActiveToolsKEY]               =       useState(undefined);

    const [buyModalVIS, setBuyModalVIS]                     =       useState(false);
    const history                                           =       useHistory();

    const onToggleCollapsedNav = () => {
        dispatch(toggleCollapsedSideNav(!navCollapsed));
    };

    useEffect(() => {
        setSidebarCollapsed(navStyle === NAV_STYLE_MINI_SIDEBAR)
    }, [navStyle])

    useEffect(() => {
        if(width < TAB_SIZE){
            setSidebarCollapsed(false)
            dispatch(toggleCollapsedSideNav(false));
        }
    }, [width, dispatch])

    let drawerStyle = "gx-collapsed-sidebar";

    if (navStyle === NAV_STYLE_FIXED) {
        drawerStyle = "";
    } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
        drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar";
    } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
        drawerStyle = "gx-custom-sidebar"
    } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
        drawerStyle = "gx-mini-sidebar";
    } else if (navStyle === NAV_STYLE_DRAWER) {
        drawerStyle = "gx-collapsed-sidebar"
    }
    if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR
        || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) && width < TAB_SIZE) {
        drawerStyle = "gx-collapsed-sidebar"
    }



    useEffect(()=>{
        setTimeout(()=>{
            setNewsShownOnce(true);
        }, 6000);

    }, [db, authUser]);


    return (
        <>
      
        
            <Sider
                className={`gx-app-sidebar ${drawerStyle} ${themeType !== THEME_TYPE_LITE ? 'gx-layout-sider-dark' : null}`}
                trigger={null}
                collapsed={(width < TAB_SIZE ? false : sidebarCollapsed || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR)}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                collapsible
            >
            {
                navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ?
                <Drawer
                    size            =   {'small'}
                    width           =   {'320'}
                    className       =   {`gx-drawer-sidebar ${themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
                    placement       =   "left"
                    closable        =   {false}
                    onClose         =   {onToggleCollapsedNav}
                    visible         =   {navCollapsed}>
                    <SidebarContent sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} isMobile={true} onClose = {onToggleCollapsedNav} />
                </Drawer> :

                
                <SidebarContent sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} isMobile={false} />
            }
            </Sider>
        
            <PopupNewsSlider newsShownOnce={newsShownOnce}/>
            </>


       
        )
};
export default Sidebar;
