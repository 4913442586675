import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, Popover } from "antd";
import { Link, useHistory } from "react-router-dom";
// import logo from '../assets/images/logo.png'
import firebase from "firebase";
import { useDispatch, useSelector } from "react-redux";

import {
    hideMessage,
    showAuthLoader,
    userSignUp,
    switchLanguage
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import { message } from "antd/lib/index";
import CircularProgress from "../components/CircularProgress";

import { UserOutlined, LockFilled, MailOutlined } from "@ant-design/icons";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./Topbar/languageData";

import DarkLightMode from "../components/DarkLightMode";
import { decodeString } from "../util/misc";

const database          = firebase.database();

const SignUp = (props) => {

    const dispatch                                          = useDispatch();
    const history                                           = useHistory();
    const { loader, alertMessage, showMessage, authUser }   = useSelector(({ auth }) => auth);

    const { locale }                                        = useSelector(({ settings }) => settings);
    const [referralID, setReferralID]                       = useState(undefined);

    const [localLoading, setLocalLoading]                   = useState(false);

    useEffect(() => {

        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }
        //if there is existing user then redirect to his dashboard
        if (authUser !== null) {
            setLocalLoading(true);
            console.log('authUser: ', authUser);
            history.push(`/main/dashboard`);
        }

        if (history.location.search){
            if ((history.location.search).includes('?ref=')){
                let totalSearchQUERY        = history.location.search;
                let referral_UID            = totalSearchQUERY.replace('?ref=', '');
                if (referral_UID && referral_UID.length !== 5){
                    setReferralID(current_referral_UID=>{
                        if (current_referral_UID === undefined){
                            database.ref(`USERS/${referral_UID}/PublicInfo/UserName`).once('value')
                            .then(snapshot=>{
                                if (snapshot.val()){
                                    message.info(`You are being referred by ${decodeString(snapshot.val())}`);
                                }
                            })
                        }
                        return referral_UID;
                    });
                }
            }
        }
    });

    const onFinishFailed = errorInfo => {
    };

    const onFinish = async values => {

        values['referralID']        = referralID;

        console.log('FORM VALUES', values);
        dispatch(showAuthLoader());
        dispatch(userSignUp(values));
    };



    return (
        <div className="gx-app-login-wrap">


            {/* <div className="gx-app-login-container sign-up-page-wrapper">
                <div className="gx-app-login-main-content sign-up-page-flex">
                    <div className="gx-app-logo-content left-column">
                        <Link to="/welcome" style={{textAlign: 'center'}}>
                            <div className="gx-app-logo">
                                <img alt="brand" src={logo} />
                            </div>
                        </Link>
                    </div>
                    <div className="gx-app-login-content right-column">
                        <div className="right-column-wrapper">
                            <div className="welcome-text">
                                <h3><IntlMessages id="appModule.hello" /></h3>
                                <p><IntlMessages id="appModule.createAccountText" /></p>
                            </div>


                            <Form
                                initialValues={{ remember: true }}
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                className="gx-signin-form gx-form-row0">

                                <label className="uk-form-label"> <IntlMessages id="appModule.name" /></label>
                                <Form.Item autoComplete    =   "off" className="uk-position-relative"
                                    rules={[{ required: true, message: 'Please enter a name!' }]} name="username">
                                    <Input autoComplete    =   "off" placeholder="Jane Foster" prefix={<UserOutlined />} />
                                </Form.Item>


                                <label className="uk-form-label"> <IntlMessages id="appModule.email" /></label>
                                <Form.Item autoComplete    =   "off" className="uk-position-relative"
                                    rules={[{ required: true, message: 'The input is not valid E-mail!' }]} name="email">
                                    <Input autoComplete    =   "off" placeholder="example@gmail.com" type="email" prefix={<MailOutlined />} />
                                </Form.Item>

                                <label className="uk-form-label"> <IntlMessages id="appModule.password" /></label>
                                <Form.Item autoComplete    =   "new-password"  className="uk-position-relative"
                                    rules={[{ required: true, message: 'Please input your Password!' }]} name="password">

                                    <Input.Password autoComplete    =   "new-password" type="password" placeholder="Password" prefix={<LockFilled />} />
                                </Form.Item>


                                <Form.Item>

                                    <Button loading={localLoading} type="primary" className="gx-mb-0 uk-button-primary signup-btn btn-block" htmlType="submit">
                                    &nbsp;<IntlMessages id="app.userAuth.signUp" />
                                    </Button>


                                </Form.Item>


                                <div className="gx-flex-row gx-justify-content-between">
                                    <Link className="gx-login-form-forgot" to="/forgot-password"><IntlMessages id="app.userAuth.forgotPassword" /></Link>

                                    <Link to="/signin"><IntlMessages
                                        id="app.userAuth.signIn" /></Link>

                                </div>

                            </Form>


                        </div>
                    </div>



                    {loader &&
                        <div className="gx-loader-view">
                            <CircularProgress />
                        </div>
                    }
                    {showMessage &&
                        message.error(alertMessage)}
                </div>
            </div> */}
        </div>
    );
};


export default SignUp;
