import React, { useEffect, useState } from "react";
import { Layout, message, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";
import Topbar from "../Topbar/index";
import App from "../../routes/index";
import firebase from "firebase";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import { updateWindowWidth } from "../../appRedux/actions";
import AppSidebar from "./AppSidebar";
import { useTranslation } from "react-i18next";
import {get} from "lodash-es";
import CircularProgress from "../../components/CircularProgress";

const { Content, Footer }   = Layout;
const database              = firebase.database();
const version               = get(require('../../autobuild_version.json'), 'version', '2');

const getContainerClass = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_DARK_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_BELOW_HEADER:
      return "gx-container-wrap";
    case NAV_STYLE_ABOVE_HEADER:
      return "gx-container-wrap";
    default:
      return "";
  }
};

const getNavStyles = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return <Topbar />;
    case NAV_STYLE_DARK_HORIZONTAL:
      return <HorizontalDark />;
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return <InsideHeader />;
    case NAV_STYLE_ABOVE_HEADER:
      return <AboveHeader />;
    case NAV_STYLE_BELOW_HEADER:
      return <BelowHeader />;
    case NAV_STYLE_FIXED:
      return <Topbar />;
    case NAV_STYLE_DRAWER:
      return <Topbar />;
    case NAV_STYLE_MINI_SIDEBAR:
      return <Topbar />;
    case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
      return <NoHeaderNotification />;
    case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
      return <NoHeaderNotification />;
    default:
      return null;
  }
};

const MainApp = () => {

    const { navStyle }                              =   useSelector(({ settings }) => settings);
    const width                                     =   useSelector(({ common }) => common.width);
    const match                                     =   useRouteMatch();
    const dispatch                                  =   useDispatch();
    const { t }                                     =   useTranslation();
    const { db }                                    =   useSelector(({ auth }) => auth);
    const [loading, setLoading]                     =   useState(false)

    

    useEffect(() => {
        console.log('MainApp Mounted with Version: ', version);
        window.addEventListener("resize", () => {
            dispatch(updateWindowWidth(window.innerWidth));
        });
    }, [dispatch]);




    return (
        <Layout className="gx-app-layout" style={{height: '100svh'}}>
          
            {
              loading === true ? 
              <div className="gx-d-flex gx-align-items-center gx-justify-content-center">
                <CircularProgress />
              </div>

              :

              <>
                  <AppSidebar navStyle={navStyle} />
                    <Layout> 
                        {width < TAB_SIZE ? getNavStyles(navStyle):<></>}                  
                        <Content
                          className={`gx-layout-content ${getContainerClass(navStyle)} `}
                        >
                            <App match={match} />

                            {/* <Footer>
                              <div className="gx-layout-footer-content" style={{fontSize: '10px'}}>
                                    
                                </div>
                            </Footer> */}

                        </Content>


                    </Layout>
              </>
            }
                
                    
        </Layout>
    );
};
export default MainApp;
