import React from 'react';
import { Row, Col, Card, Typography, Progress } from 'antd';

const { Title, Text } = Typography;

const skillsData = [
  { title: 'Adobe Photoshop', img: '/assets/images/photoshop.png', progress: 80 },
  { title: 'Adobe InDesign', img: '/assets/images/indesign.png', progress: 70 },
  { title: 'Adobe Illustrator', img: '/assets/images/illustrator.png', progress: 85 },
  { title: 'Figma', img: '/assets/images/figma.png', progress: 90 },
  { title: 'Adobe XD', img: '/assets/images/xdesign.png', progress: 75 },
  { title: 'Adobe Premiere Pro', img: '/assets/images/premiere.png', progress: 65 },
  { title: 'Adobe Lightroom', img: '/assets/images/lightroom.png', progress: 60 },
  { title: 'Adobe After Effects', img: '/assets/images/aeffect.png', progress: 70 },
];

const SkillsSection = () => (
  <div className='gx-skills-section gx-section gx-bg-white'>
    <div className="gx-container">
      <div className="gx-section-title-wrapper gx-text-center gx-mb-5">
        <span className='gx-section-child-title'>My Skills</span>
        <h4 className="gx-section-subtitle">
          Let's Explore Popular
        </h4>
        <h1 className="gx-section-title">
          Skills & Experience
        </h1>
      </div>
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={22} xl={16} xxl={12}>
          <Row gutter={{
      xs: [20, 20], // Small devices
      sm: [60, 20], // Tablets and small laptops
      md: [60, 60], // Medium devices
      lg: [60, 60], // Larger devices
      xl: [60, 60], // Extra large devices
    }}  
          justify="center">
            {skillsData.map((skill, index) => (
              <Col xs={12} sm={12} md={8} lg={6} key={index}>
                <Card
                  className='gx-skills-card'
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#D9D9D9',
                    border: '1px solid #9C9C9C',
                    borderRadius: '8px',
                  }}
                >
                  <div className="gx-skills-card-content">

                    <div className="gx-skill-card-top-content" style={{minHeight: '130px'}}>
                      <img
                        src={`${skill.img}`} // Replace with actual image paths
                        alt={skill.title}
                        style={{ width: '64px', marginBottom: '16px' }}
                        loading='lazy'
                      />
                      <h2 className='gx-skill-title gx-mb-1'><span>{skill.title?.split(' ')[0]}</span> <span className='gx-d-block'>{skill.title.split(' ')?.slice(1)?.join(' ')}</span></h2>
                    </div>
                    <div style={{ marginTop: '10px', width: '100%' }}>
                      <Progress
                      className='gx-mb-0'
                        percent={skill.progress}
                        showInfo={false}
                        strokeColor="#959595"
                        trailColor="#b7b7b7"
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>

  </div>
);

export default SkillsSection;
