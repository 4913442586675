import React, { useState, useEffect } from "react";
import { Input, Popover, Button, Avatar,Row,Col, Skeleton, Modal, message} from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import { DeleteOutlined, EditOutlined, EllipsisOutlined, MessageOutlined, ThunderboltOutlined } from '@ant-design/icons';
import SidebarLogo from "./SidebarLogo";
import { useDispatch, useSelector } from 'react-redux';

import {get} from "lodash-es";
import { decodeString, encodeString } from "../../util/misc";
import { setActiveChatKey, setIsLiveTypingEnabled, userSignOut } from "../../appRedux/actions";
import firebase from "firebase";
import Mixpanel from "../../api/Mixpanel";
import { TAB_SIZE } from "../../constants/ThemeSetting";

const database      = firebase.database();

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed, onClose, isMobile }) => {
    

    const dispatch                                          = useDispatch();
    const [sideBarChatSessions, setSidebarChatSessions]     = useState(<></>);
    const { db, activeChatKey }                             = useSelector(({ auth }) => auth);
    const width                                             = useSelector(({common}) => common.width);
    const navCollapsed                                      = useSelector(({ common }) => common.navCollapsed);

    const [isLoading, setIsLoading]                         = useState(true);

    const [chatSessionTitleVisible, setChatSessionTitleVis] = useState(false);
    const [toUpdateChatTitleKey, setToUpdateChatTitleKey]   = useState(undefined);
    const [deleteChatSessionVIS, setDeleteChatSessionVis]   = useState(false);
    const [newTitle, setNewTitle]                           = useState('');

    useEffect(()=>{
        if (db && activeChatKey !== undefined){

            let outputJSX                       = [];
            let allCurrentSessions              = get(db, ['Sessions'], undefined);

            dispatch(setIsLiveTypingEnabled(false));

            console.log('Setting up side-bar chat sessions with active key being: ', activeChatKey);
           

            for (let k in allCurrentSessions){

                console.log('COMPARATORS: ', parseInt(k), parseInt(activeChatKey));
                let uiTitleToShow = (decodeString(get(allCurrentSessions, [k, 'title'], 'New chat').replaceAll('"', ''))).slice(0,13);

                outputJSX.push(
                    <div onClick={()=>{
                        dispatch(setActiveChatKey(k));
                    }} style={{width: '95%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '3%', marginLeft: '2.5%', padding: '2%', borderRadius: '2px', background: parseInt(k) === parseInt(activeChatKey) ? '#38424b': 'rgba(0,0,0,0)', cursor: 'pointer'}}>
                        <div style={{width: '70%',margin: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'
                        }} >
                            <MessageOutlined className="gx-mr-2 gx-ml-1" style={{color: 'white'}}/>
                            <label style={{color: 'white'}} className="gx-mx-0 gx-mb-0">{uiTitleToShow}</label>
                        </div>

                        {parseInt(k) === parseInt(activeChatKey) ? <>
                        
                            <Button size="small" style={{margin: 0}} onClick={()=>{
                                    setToUpdateChatTitleKey(k);
                                    setNewTitle((decodeString(get(allCurrentSessions, [k, 'title'], 'New chat').replaceAll('"', ''))));
                                    setChatSessionTitleVis(true);
                                }}>
                                    <EditOutlined />
                            </Button>


                            <Button size="small" style={{margin: 0}} onClick={()=>{
                                setToUpdateChatTitleKey(k);
                                setDeleteChatSessionVis(true);
                            }}>
                                <DeleteOutlined />
                            </Button>


                        
                        
                        </>: <></>}
                    </div>
                )
            }
            
            outputJSX.reverse();
            setSidebarChatSessions(outputJSX);
            setIsLoading(false);
        }
    }, [db, dispatch, activeChatKey])

    const createNewChatSession = () => {
        //create a new Chat Session
        if (db){
            let currentTime = (new Date()).getTime();

            database.ref(`USERS/${db['UID']}/Sessions/${currentTime}`).update({
                key         : currentTime,
                title       : 'New chat'
            });

            database.ref(`GPT-Conversations/${db['UID']}/Sessions/${currentTime}`).push({
                'role'      : 'assistant',
                'content'   : "Hello! Very nice to meet you. Anything I can help you with?",
                'timeStamp' : (new Date()).getTime()
            });

            dispatch(setActiveChatKey(currentTime));

            Mixpanel.record({eventName: 'NEW-CHAT-SESSION-CREATED', eventProperties: {
                uid: db['UID'], timeStamp: (new Date()).getTime()
            }});
        }
    }

    return (
        <>
            <SidebarLogo
                sidebarCollapsed={sidebarCollapsed}
                setSidebarCollapsed={setSidebarCollapsed}
                onClose         =   {onClose}
            />


            {
                sidebarCollapsed && !isMobile ? <></> :
    
                <div className="gx-sidebar-content">
                    <CustomScrollbars 
                        className="gx-layout-sider-scrollbar"
                    >

                        {sideBarChatSessions.length === 0 ? 
                            <Row align="center" justify="middle" className="gx-mt-2">
                                <Col span={24} className="gx-ml-4">
                                    <Skeleton active paragraph={{ rows: 1 }} />
                                </Col>
                                <Col span={24} className="gx-ml-4">
                                    <Skeleton active paragraph={{ rows: 1 }} />
                                </Col>
                                <Col span={24} className="gx-ml-4">
                                    <Skeleton active paragraph={{ rows: 1 }} />
                                </Col>
                                <Col span={24} className="gx-ml-4">
                                    <Skeleton active paragraph={{ rows: 1 }} />
                                </Col>
                                <Col span={24} className="gx-ml-4">
                                    <Skeleton active paragraph={{ rows: 1 }} />
                                </Col>
                                <Col span={24} className="gx-ml-4">
                                    <Skeleton active paragraph={{ rows: 1 }} />
                                </Col>

                            </Row>
                            :
                            <></>
                        }

                    <>
                    {sideBarChatSessions}
                    </>


                    </CustomScrollbars>


                    <>
                        <div style={{width: '100%', height: '100%', padding: '3%'}}>

                            <div style={{width: '95%', marginLeft: '2.5%', height: '1px', background: 'lightgray'}}></div>



                            <div className="sideBarBottomTransparentBTN" style={{marginTop: '4%',display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '4%', borderRadius: '4px', cursor: 'pointer', width: '95%', marginLeft: '2.5%'}}>
                                <ThunderboltOutlined style={{color: 'white'}} className="gx-mr-3 gx-ml-1"/> 
                                
                                <label style={{color: 'white'}}>Upgrade To Pro</label>
                                

                            </div>

                            <Popover trigger="click" content={<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <Button block>Custom Instructions</Button>
                                <Button block>Settings</Button>
                                <div style={{width: '95%', marginLeft: '2.5%', height: '1px', background: 'lightgray'}}></div>
                                <Button block onClick={()=>{
                                    dispatch(userSignOut());
                                }}>Log Out</Button>
                            </div>}>

                                <div className="sideBarBottomTransparentBTN" style={{marginTop: '4%',display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '4%', borderRadius: '4px', cursor: 'pointer', width: '95%', marginLeft: '2.5%'}}>
                                    <div>
                                        <Avatar shape="square" className="gx-size-20 gx-mx-0 gx-mr-3" src={decodeString(get(db, ['PublicInfo', 'avatarURL'], '"https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png"'))} alt={decodeString(get(db, ['PublicInfo', 'avatarURL'], '"https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png"'))}/>

                                        <label style={{color: 'white'}}>{decodeString(get(db, ['PublicInfo', 'UserName'], 'Loading'))}</label>
                                        
                                    </div>
                                    <EllipsisOutlined style={{color: 'white'}}/>
                                </div>

                            </Popover>

                        </div>
                    </>


                    <Modal 
                        title           =   {'New Chat Session Title'}
                        visible         =   {chatSessionTitleVisible}
                        width           =   {400}
                        style           ={{
                                            top: 50,
                                            left: 20
                                        }}
                        bodyStyle       =   {{padding: '10px', margin: 0}}
                        footer          =   {[
                            <Button type="primary" onClick={()=>{
                                if (toUpdateChatTitleKey === undefined){
                                    message.error('Could not find valid chat session')
                                    return false;
                                }
            
                                if (newTitle){
                                    setChatSessionTitleVis(false);
                                    database.ref(`USERS/${db['UID']}/Sessions/${toUpdateChatTitleKey}`).update({
                                        'title': encodeString(newTitle)
                                    })
                                    .then(()=>{
                                        setToUpdateChatTitleKey(undefined);
                                    })
                                }
                            }}>
                                Update
                            </Button>
                        ]}
                        onCancel        =   {()=>setChatSessionTitleVis(false)}
                    >
                        <Input value={newTitle} onChange={e=>setNewTitle(e.target.value)}/>
                
                    </Modal>

                    <Modal 
                        title           =   {'Are you sure?'}
                        visible         =   {deleteChatSessionVIS}
                        width           =   {350}
                        placement       =   {'top'}
                        style           =   {{ top: 30 , left: 5}}
                        bodyStyle       =   {{padding: 20, margin: 0}}
                        footer          =   {[
                            <Button type="primary" onClick={()=>{
                                if (toUpdateChatTitleKey === undefined){
                                    message.error('Could not find valid chat session')
                                    return false;
                                }
            
                                setDeleteChatSessionVis(false);
                                database.ref(`USERS/${db['UID']}/Sessions/${toUpdateChatTitleKey}`).remove()
                                .then(()=>{

                                    //pick a new chat session to show instead now
                                    let allCurrentSessions              = get(db, ['Sessions'], {});
                                    let availableKeysArr                = [];
                                    for (let k in allCurrentSessions){
                                        if (parseInt(k) !== parseInt(toUpdateChatTitleKey)){
                                            availableKeysArr.push(k);
                                        }
                                    }

                                    availableKeysArr.sort((a,b)=>{
                                        return (parseInt(b) - parseInt(a))
                                    });

                                    if (availableKeysArr.length === 0){
                                        //create a new chat and make that the new active key
                                        createNewChatSession();
                                    }
                                    else{
                                        //pick the latest remaining active sessions and set that as the main
                                        dispatch(setActiveChatKey(availableKeysArr[0]));
                                    }

                                    setToUpdateChatTitleKey(undefined);
            
                                })
                            }}>
                                Yes
                            </Button>
                        ]}
                        onCancel        =   {()=>setDeleteChatSessionVis(false)}
                        closable        =   {true}
                    >
                        <p>Please proceed with caution</p>
                    </Modal>
                </div>
            }
        
        </>
    );
};

export default React.memo(SidebarContent);