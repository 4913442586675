import { Button, message, Carousel , Row, Col} from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useRef, useState, useEffect } from "react";


import firebase from "firebase";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {get} from "lodash-es";
import { getRandomInt } from "../../util/misc";

const database = firebase.database();


const PopupNewsSlider = ({newsShownOnce}) => {

    const carousel                                  = useRef(undefined);
    const { db, authUser }                          = useSelector(({ auth }) => auth);
    const { t }                                     = useTranslation();
    const [mode, setMode]                           = useState("SMS");
    const [isModalVisible, setIsModalVisible]       = useState(false);

    const [sliderJSX, setSliderJSX]                 = useState([]);


    const goNextSlide = () => {
        if (carousel){
            carousel.current.next();
        }
    }

    //setup the popup news slider
    useEffect(() => {
        let currentAppMode = get(db, ["ReactAppMode"], undefined);

        if (currentAppMode === "SMS_LMS_GATEWAY") {
            setMode("LMS");
        } 
        else {
            setMode("SMS");
        }

        let outputCarouselJSX = [];
        //setup the popup news slider
        if (db && newsShownOnce === undefined){
            if (db['UID'] === authUser && db['PublicInfo']['UserProffession'] !== 'Student'){
                database.ref('StaticData/PopupNewsTeacher').once('value')
                    .then(snapshot=>{
    
                        if (!snapshot.val()) return false;
    
                        let allNews = snapshot.val();
                        if (!allNews) allNews = {};
                        let ownDB_NEWS_DO_NOT_SHOW = get(db, ['PublicInfo', 'DO-NOT-SHOW-NEWS-KEYS'], {});
    
                        for (let oneNewsKey in allNews){
    
                            if (ownDB_NEWS_DO_NOT_SHOW[oneNewsKey] === oneNewsKey) continue;
    
                            //this means the user has not clicked 'do not show' to this news so take it and enter it
                            let {newsArray} = allNews[oneNewsKey];
    
                            let index = 0
                            for (let oneNewsSlide of newsArray){
    
                                let {image, title, description} = oneNewsSlide
    
                                outputCarouselJSX.push(                
                                    <div key={getRandomInt(0,999999999)} >
                                        <Row align="middle" justify="space-between" style={{padding: '15%', paddingLeft: '10%', paddingRight: '10%'}}>
                    
                                            <Col sm={{span:12}} md={{span : 12}}>
                                                <img 
                                                    style       =   {{paddingBottom: 0}} 
                                                    className   =   "gx-slider-img dashboard-top-img" 
                                                    alt         =   "welcomeImage" 
                                                    src         =   {image}
                                                />
                                            
                                            </Col>
                    
                                            <Col sm={{span:12}} md={{span : 12}}>
                                                <Row align="middle" justify="center">
                                                    <Col sm={{span:24}} md={{span : 24}}>
                                                        <h1>{title}</h1>
                                                        <br></br>
                                                    </Col>
                                                    <Col sm={{span:24}} md={{span : 24}}><p>{description}</p></Col>
                                                    <Col sm={{span:24}} md={{span : 24}}>
                                                        
    
                                                        {index+1 === newsArray.length ? <Button type="primary" onClick={()=>setIsModalVisible(false)}>Lets Go</Button> : <Button type="primary" onClick={()=>goNextSlide()}>NEXT</Button>}
    
                                                        {/* if this is last slide then show the 'do not show again button' */}
                                                        {index+1 === newsArray.length ?  <Button type="link" onClick={()=>{
                                                                    database.ref(`USERS/${db['UID']}/PublicInfo/DO-NOT-SHOW-NEWS-KEYS`).update({
                                                                        [oneNewsKey]: oneNewsKey
                                                                    })
                                                                    .then(()=>{
                                                                        setIsModalVisible(false);
                                                                        message.info('Ok, you will not see this news again!');
                                                                    })
                                                        }}>Do Not Show Again</Button> : <></>}
                                                    </Col>
                                                </Row>
                                            </Col>
                    
                                        </Row>
                                    </div>
                                )
    
                                index++
                            }
    
                            if (outputCarouselJSX.length !== 0){
                                break
                            }
    
                        }
    
                        setSliderJSX(outputCarouselJSX);
                        if (outputCarouselJSX.length !== 0){
                            setIsModalVisible(true); 
                        }
    
                    })
            }
        }

    }, [db, newsShownOnce, authUser]);

    return (
        <>
            <Modal
                className       =   "gx-payment-modal "
                width           =   {800}
                title           =   {''}
                footer          =   {null}
                bodyStyle       =   {{paddingTop: '22px'}}
                visible         =   {isModalVisible}
                onOk            =   {()=>setIsModalVisible(false)}
                onCancel        =   {()=>setIsModalVisible(false)}
                closable        =   {false}
            >

            <Carousel effect="fade" ref={carousel}>
                {sliderJSX}
            </Carousel>

            </Modal>
        </>
    );
};

export default PopupNewsSlider;
