import React, { useRef, useState } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { BehanceOutlined, InstagramOutlined, WhatsAppOutlined, YoutubeFilled } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";
// import emailjs from "@emailjs/browser";

const ContactSection = () => {
  const [form] = Form.useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault(); // Prevent the default form submission


    // emailjs
    //   .sendForm(
    //     "service_e1g98ca", // Replace with your EmailJS service ID
    //     "template_53koopj", // Replace with your EmailJS template ID
    //     formRef.current,
    //     "2TUo5EUuiVV0-j70W" // Replace with your EmailJS public key
    //   )
    //   .then(
    //     (result) => {
    //       setSuccessMessage("Message sent successfully!");
    //       setErrorMessage("");
    //       formRef.current.reset(); // Reset the form after submission
    //     },
    //     (error) => {
    //       setErrorMessage("Failed to send message. Please try again.");
    //       setSuccessMessage("");
    //     }
    //   );

    // formRef.current.reset();


  };

  const onFinishFailed = errorInfo => { console.log(errorInfo) };


  //submit add new student
  const onFinish = values => {
    message.success("Message sent successfully!")
    form.resetFields()

    // emailjs
    //   .sendForm(
    //     "service_e1g98ca", // Replace with your EmailJS service ID
    //     "template_53koopj", // Replace with your EmailJS template ID
    //     formRef.current,
    //     "2TUo5EUuiVV0-j70W" // Replace with your EmailJS public key
    //   )
    //   .then(
    //     (result) => {
    //       setSuccessMessage("Message sent successfully!");
    //       setErrorMessage("");
    //       formRef.current.reset(); // Reset the form after submission
    //     },
    //     (error) => {
    //       setErrorMessage("Failed to send message. Please try again.");
    //       setSuccessMessage("");
    //     }
    //   );

  }


  return (
    <div className="gx-contact-section gx-section gx-bg-white">
      <div className="gx-container">
        <Row gutter={[32, 16]} align="middle">
          {/* Left Section */}
          <Col xs={24} md={12}>
            <div className="gx-section-title-wrapper">
              <h4 className="gx-section-subtitle">Get In Touch</h4>
              <h1 className="gx-section-title">
                LET'S TALK <br />
                FOR YOUR NEXT <br />
                PROJECTS
              </h1>
            </div>
          </Col>

          {/* Right Section */}
          <Col xs={24} md={12}>

            <Form form={form}
              name="Add One Student Modal Drawer"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed} layout="vertical" className="gx-force-two-cols">
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item name="user_name" label="Name">
                    <Input name="user_name" placeholder="Enter your name" required />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="user_phone" label="Phone Number">
                    <Input name="user_phone" placeholder="Enter your phone number" required />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item name="user_email" label="Email">
                    <Input name="user_email" type="email" placeholder="Enter your email" required />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="user_subject" label="Subject">
                    <Input name="user_subject" placeholder="Enter the subject" required />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="message" label="Message">
                <Input.TextArea name="message" rows={4} placeholder="Enter your message" required />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "auto", display: "inline-block" }}
                >
                  Send Message
                </Button>
              </Form.Item>
            </Form>

            {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          </Col>
        </Row>

        <Row gutter={[32, 16]}>
          <Col>
            <div style={{ marginTop: "24px", display: "flex", gap: "16px" }}>
              <div className="gx-icon-wrapper">
                <WhatsAppOutlined
                  onClick={() =>
                    window.open(
                      "https://wa.me/971561876465?text=Hello,%20I%20would%20like%20to%20get%20in%20touch!",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  className="gx-icon"
                  style={{ color: "#545454" }}
                />
              </div>
              <div className="gx-icon-wrapper">
                <InstagramOutlined
                  onClick={() => window.open("https://www.instagram.com/sdnoronyo/", "_blank", "noopener,noreferrer")}
                  className="gx-icon"
                  style={{ color: "#545454" }}
                />
              </div>
              <div className="gx-icon-wrapper">
                <FontAwesomeIcon
                  onClick={() => window.open("https://www.facebook.com/sdnoronyo", "_blank", "noopener,noreferrer")}
                  icon={faFacebookF}
                  className="gx-icon"
                  style={{ color: "#545454" }}
                />
              </div>
              <div className="gx-icon-wrapper">
                <BehanceOutlined
                  onClick={() => window.open("https://www.behance.net/sdn_oronyo", "_blank", "noopener,noreferrer")}
                  className="gx-icon"
                  style={{ color: "#545454" }}
                />
              </div>
              <div className="gx-icon-wrapper">
                <FontAwesomeIcon
                  onClick={() => window.open("https://www.linkedin.com/in/sdnoronyo/", "_blank", "noopener,noreferrer")}
                  icon={faLinkedinIn}
                  className="gx-icon"
                  style={{ color: "#545454" }}
                />
              </div>
              <div className="gx-icon-wrapper">
                <YoutubeFilled
                  onClick={() => window.open("https://www.youtube.com/@sdnoronyo2059", "_blank", "noopener,noreferrer")}
                  className="gx-icon"
                  style={{ color: "#545454" }}
                />
              </div>
              <div className="gx-icon-wrapper">
                <FontAwesomeIcon
                  onClick={() => window.open("https://x.com/sdn_oronyo", "_blank", "noopener,noreferrer")}
                  icon={faTwitter}
                  className="gx-icon"
                  style={{ color: "#545454" }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContactSection;
