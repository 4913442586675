import React, {useEffect} from "react";
import { Provider } from 'react-redux'
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { ConnectedRouter } from 'connected-react-router'
import {Route, Switch} from "react-router-dom";
import "assets/vendors/style";
import configureStore, { history } from './appRedux/store';
import "./firebase/firebase";
import App from "./containers/App/index";
// import RollbarEdutechs from "./api/RollbarEdutechs";

const store = configureStore({});

export type RootState       = ReturnType<typeof store.getState>
export type AppDispatch     = typeof store.dispatch




const NextApp = () => {

    useEffect(() => {
        window.process = {
          ...window.process,
        };
    }, []);

    // const rollbarConfig = RollbarEdutechs.init();  

    return (
        <RollbarProvider 
        // instance={rollbarConfig}
        >
            <ErrorBoundary>

                <Provider store={store} >
                    <ConnectedRouter history={history}>
                        <Switch>
                            <Route path="/" component={App}/>
                        </Switch>
                    </ConnectedRouter>
                </Provider>

            </ErrorBoundary>

        </RollbarProvider>

    )
}



export default NextApp;