import React from 'react';
import { Carousel } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';


const PortfolioSection = () => {
    const projects = [
        {
            image: '/assets/images/s1.jpg',
            title: 'Motion Graphic Projects',
        },

        {
            image: '/assets/images/s2.jpg',
            title: 'Web Design Project',
        },
        {
            image: '/assets/images/s3.png',
            title: 'Box Packaging Project',
        },
        {
            image: '/assets/images/s2.jpg',
            title: 'Web Design Project',
        },
        {
            image: '/assets/images/s2.jpg',
            title: 'Web Design Project',
        },
    ];

    // Custom Arrow Components
    const PrevArrow = ({ onClick }) => (
        <div className="gx-carousel-arrow gx-carousel-arrow-left" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30px', height: '30px', border: '1px solid #000', borderRadius: '50%' }} onClick={onClick}>
            <ArrowLeftOutlined className='gx-carousel-icon' style={{ fontSize: '16px', fontWeight: '400', color: '#000' }} />
        </div>
    );

    const NextArrow = ({ onClick }) => (
        <div className="gx-carousel-arrow gx-carousel-arrow-right" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30px', height: '30px', border: '1px solid #000', borderRadius: '50%' }} onClick={onClick}>
            <ArrowRightOutlined className='gx-carousel-icon' style={{ fontSize: '16px', fontWeight: '400', color: '#000' }} />
        </div>
    );

    const carouselSettings = {
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1440, // For laptops and larger screens
                settings: { slidesToShow: 3 }, // Show 3 slides on laptops
            },
            {
                breakpoint: 1024, // For tablets
                settings: { slidesToShow: 3 },
            },
            {
                breakpoint: 720, // For medium-sized devices
                settings: { slidesToShow: 2 },
            },
            {
                breakpoint: 480, // For mobile devices
                settings: { slidesToShow: 1 },
            },
        ],
    };

    return (
        <div className="gx-portfolio-section gx-section gx-bg-white">
            <div className="gx-container">
                <div className="gx-section-title-wrapper gx-text-center gx-mb-5">
                    <h4 className="gx-section-subtitle">
                        GRAPHIC & BRAND DESIGNER
                    </h4>
                    <h1 className="gx-section-title">
                        PORTFOLIO
                    </h1>
                </div>
                <Carousel {...carouselSettings} className="gx-portfolio-carousel">
                    {projects.map((project, index) => (


                        <div
                            key={index}
                            className="gx-portfolio-item"
                           
                            style={{ padding: '0 15px', cursor: 'pointer' }} // Cursor pointer for clickable UI
                            onClick={() => window.open('https://www.behance.net/sdn_oronyo', '_blank')} // Open link in new tab
                        >
                            <div
                                className="gx-portfolio-card"
                                style={{
                                    position: 'relative',
                                    overflow: 'hidden',
                                    cursor: 'pointer',
                                }}
                            >
                                {/* Image */}
                                <img
                                    src={project.image}
                                    alt=""
                                    loading='lazy'
                                    style={{
                                        display: 'block',
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '20px',
                                        filter: 'brightness(0.6)', // Reduces brightness for a dark effect
                                        transition: 'filter 0.3s ease', // Smooth transition for hover effect
                                    }}
                                />





                                {/* Dark overlay and icon */}
                                <div
                                    className="gx-portfolio-overlay"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.3)', // Dark overlay
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        opacity: 0, // Initially hidden
                                        transition: 'opacity 0.3s ease', // Smooth hover effect
                                        zIndex: 10,
                                        borderRadius: '20px'
                                    }}
                                >
                                    <ArrowUpOutlined
                                        style={{
                                            fontSize: '20px', // Icon size
                                            color: '#000', // Icon color
                                            background: '#fff',
                                            padding: '8px',
                                            borderRadius: '50%',
                                            transform: 'rotate(50deg)'
                                        }}
                                    />
                                </div>

                                {/* Title text */}
                                <div
                                    className="gx-portfolio-title-overlay"
                                    style={{
                                        position: 'absolute',

                                        transition: 'opacity 0.3s ease', // Smooth hiding effect
                                        zIndex: 5,
                                    }}
                                >
                                    {project.title.split(' ').slice(0, 2).join(' ')}
                                    <span className="gx-d-block">
                                        {project.title.split(' ').slice(2).join(' ')}
                                    </span>
                                </div>
                            </div>
                        </div>


                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default PortfolioSection;
