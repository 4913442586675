// Customizer const
export const TOGGLE_COLLAPSED_NAV           = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH                   = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE                = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START                    = "fetch_start";
export const FETCH_SUCCESS                  = "fetch_success";
export const FETCH_ERROR                    = "fetch_error";
export const SHOW_MESSAGE                   = "SHOW_MESSAGE";
export const HIDE_MESSAGE                   = "HIDE_MESSAGE";
export const ON_SHOW_LOADER                 = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER                 = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER                    = "SIGNUP_USER";
export const SIGNIN_PHONE_USER              = "SIGNIN_PHONE_USER";
export const SIGNIN_DEV_USER                = "SIGNIN_DEV_USER";
export const SIGNIN_PHONE_USER_SMS_CODE     = "SIGNIN_PHONE_USER_SMS_CODE";
export const SIGNIN_PHONE_USER_SUCCESS      = "SIGNIN_PHONE_USER_SUCCESS";
export const SIGNUP_USER_SUCCESS            = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER             = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS     = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER           = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS   = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER            = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS    = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER             = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS     = "signin_github_user_success";
export const SIGNIN_USER                    = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS            = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER                   = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS           = "SIGNOUT_USER_SUCCESS";
export const INIT_URL                       = "INIT_URL";


//DB CONSTANTS
export const LOCAL_DB                           = "LOCAL_DB";
export const UPDATE_IN_LOCAL_DB                 = "UPDATE_IN_LOCAL_DB";
export const UPDATE_MOD_TEACHER_IN_LOCAL_DB     = "UPDATE_MOD_TEACHER_IN_LOCAL_DB";
export const UPDATE_ADMIN_TEACHER_IN_LOCAL_DB   =  "UPDATE_ADMIN_TEACHER_IN_LOCAL_DB";
export const UPDATE_STUDENT_IN_LOCAL_DB         =  "UPDATE_STUDENT_IN_LOCAL_DB";
export const REMOVE_IN_LOCAL_DB                 = "REMOVE_IN_LOCAL_DB";
export const PUSH_IN_LOCAL_DB                   = "PUSH_IN_LOCAL_DB";
export const TEST_DB                            = "TEST_DB";

//GPT-CHAT
export const SET_ACTIVE_CHAT_KEY                = "SET_ACTIVE_CHAT_KEY";
export const SET_IS_LIVE_TYPING_ENABLED         = "SET_IS_LIVE_TYPING_ENABLED";

//progress bar action types
export const ADD_PROGRESS_OBJECT            = "ADD_PROGRESS_OBJECT";
export const UPDATE_PROGRESS_OBJECT         = "UPDATE_PROGRESS_OBJECT";
export const Remove_All_PROGRESS_OBJECT     = "Remove_All_PROGRESS_OBJECT";
export const Remove_PROGRESS_OBJECT_BY_ID   = "Remove_PROGRESS_OBJECT_BY_ID";

//Sticky
export const GET_STICKY                     = "GET_STICKY";
export const NOTES_UPDATE                   = "NOTES_UPDATE";
export const FETCH_ALL_NOTES_SUCCESS        = "FETCH_ALL_NOTES_SUCCESS";
export const UPDATE_ALL_NOTES_SUCCESS       = "UPDATE_ALL_NOTES_SUCCESS";

//Contact
export const GET_All_CONTACT_SUCCESS        = "GET_All_CONTACT_SUCCESS";
export const ON_ADD_CONTACT_SUCCESS         = "ON_ADD_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS         = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS         = "DELETE_CONTACT_SUCCESS";

// Upload File Types
export const UPLOAD_NEW_FILE                = "UPLOAD_NEW_FILE";
export const LOADING_UPLOADING_FILE         = "LOADING_UPLOADING_FILE";
export const SHOW_PROGRESS_UPLOADING_FILE   = "SHOW_PROGRESS_UPLOADING_FILE";
export const SUCCESS_UPLOADING_FILE         = "SUCCESS_UPLOADING_FILE";
export const ERROR_UPLOADING_FILE           = "ERROR_UPLOADING_FILE";
export const CANCEL_UPLOADING_FILE          = "CANCEL_UPLOADING_FILE";
export const CANCEL_ON_LOADING_FILE         = "CANCEL_)N_LOADING_FILE";
export const REMOVE_UPLOAD_DONE_FILE        = "REMOVE_UPLOAD_DONE_FILE";
export const REMOVE_ERROR_FILE              = "REMOVE_ERROR_FILE";
export const CLOSE_PROGRESS_BAR             = "CLOSE_PROGRESS_BAR";
export const REMOVE_CANCELLED_FILE          = "REMOVE_CANCELLED_FILE";



//STUDENT PROFILE TYPES
export const SHOW_STUDENT_PROFILE           = "SHOW_STUDENT_PROFILE";
export const CLOSE_STUDENT_PROFILE          = "CLOSE_STUDENT_PROFILE";


//SET TEACHERS UID
export const SET_ACTIVE_TEACHER_UID           = "SET_ACTIVE_TEACHER_UID";
export const SET_ALL_TEACHERS_UID             = "SET_ALL_TEACHERS_UID";

