
import {useSelector} from "react-redux";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { Button, } from "antd";
import { useDispatch } from "react-redux";
import firebase from 'firebase';
import { setActiveChatKey, toggleCollapsedSideNav } from "../../appRedux/actions";
import { CloseOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PlusOutlined } from "@ant-design/icons";
import Mixpanel from "../../api/Mixpanel";

const database      = firebase.database();


const SidebarLogo = ({sidebarCollapsed, setSidebarCollapsed, onClose}) => {

    const dispatch                                      = useDispatch();
    const navCollapsed                                  = useSelector(({common}) => common.navCollapsed);
    const width                                         = useSelector(({common}) => common.width);
    const { db }                                        = useSelector(({ auth }) => auth);

    let navStyle = useSelector(({settings}) => settings.navStyle);
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
        navStyle = NAV_STYLE_DRAWER;
    }

    const newChatClick = () => {
        //create a new Chat Session
        if (db){
            let currentTime = (new Date()).getTime();

            database.ref(`USERS/${db['UID']}/Sessions/${currentTime}`).update({
                key         : currentTime,
                title       : 'New chat'
            });

            database.ref(`GPT-Conversations/${db['UID']}/Sessions/${currentTime}`).push({
                'role'      : 'assistant',
                'content'   : "Hello! Very nice to meet you. Anything I can help you with?",
                'timeStamp' : (new Date()).getTime()
            });

            dispatch(setActiveChatKey(currentTime));

            Mixpanel.record({eventName: 'NEW-CHAT-SESSION-CREATED', eventProperties: {
                uid: db['UID'], timeStamp: (new Date()).getTime()
            }});
        }
    }

    return (
        <div style={{height: '74px', padding: '20px', paddingLeft: '15px', paddingRight: '15px'}}>

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>

                {sidebarCollapsed === true ? <></> : 
                
                    <Button style={{margin: 0, textAlign: 'left'}} block type="dashed" size="medium" onClick={()=>newChatClick()}>
                        <PlusOutlined /> 
                        New Chat
                    </Button> 
                
                }

                

                {
                    width <TAB_SIZE ?

                    <Button style={{margin: 0, marginLeft: '8px'}} type="dashed" size="medium" onClick={()=>{
                        onClose()
                    }}>
                        <CloseOutlined />
                        
                    </Button> 

                    :

                    <Button style={{margin: 0, marginLeft: '8px'}} type="dashed" size="medium" onClick={()=>{
                        setSidebarCollapsed(!sidebarCollapsed);
                        dispatch(toggleCollapsedSideNav(!sidebarCollapsed));
                    }}>
                        {!sidebarCollapsed ?  <MenuUnfoldOutlined /> :  <MenuFoldOutlined />}
                        
                    </Button> 


                }
                
                

            </div>
        </div>
    );
};

export default SidebarLogo;
