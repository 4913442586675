import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider, message } from 'antd';
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import ForceLogout from "../ForceLogout";

import { setInitUrl } from "appRedux/actions/Auth";
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "appRedux/actions/Setting";

import {
    LAYOUT_TYPE_BOXED,
    LAYOUT_TYPE_FRAMED,
    LAYOUT_TYPE_FULL,
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL, THEME_TYPE_DARK, THEME_TYPE_LITE
} from "../../constants/ThemeSetting";

import "../../i18n"


import { updateInLocalDatabase } from "../../appRedux/actions";
import { auth } from "../../firebase/firebase";

import firebase from 'firebase';

import HomePage from "../SinglePublicPages/HomePage";

const database = firebase.database();



const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) =>
    <Route
        {...rest}
        render={props =>
            authUser
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/signin',
                        state: { from: location }
                    }}
                />}
    />;

const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
        document.body.classList.remove('boxed-layout');
        document.body.classList.remove('framed-layout');
        document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
        document.body.classList.remove('full-layout');
        document.body.classList.remove('framed-layout');
        document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
        document.body.classList.remove('boxed-layout');
        document.body.classList.remove('full-layout');
        document.body.classList.add('framed-layout');
    }
};

const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
        navStyle === NAV_STYLE_DARK_HORIZONTAL ||
        navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
        navStyle === NAV_STYLE_ABOVE_HEADER ||
        navStyle === NAV_STYLE_BELOW_HEADER) {
        document.body.classList.add('full-scroll');
        document.body.classList.add('horizontal-layout');
    } else {
        document.body.classList.remove('full-scroll');
        document.body.classList.remove('horizontal-layout');
    }
};

let styleSheetLink = document.createElement('link');
styleSheetLink.type = 'text/css';
styleSheetLink.rel = 'stylesheet';
document.body.appendChild(styleSheetLink);

window.firstLoad = true;

const App = () => {

    const locale = useSelector(({ settings }) => settings.locale);
    const navStyle = useSelector(({ settings }) => settings.navStyle);
    const layoutType = 'full-layout';
    const themeColor = useSelector(({ settings }) => settings.themeColor);
    const themeType = useSelector(({ settings }) => settings.themeType);
    const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);

    const dispatch = useDispatch();
    const { authUser, initURL, db } = useSelector(({ auth }) => auth);

    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();
    const [isDarkMode, setDarkMode] = useState(
        localStorage.getItem('themeType') === THEME_TYPE_DARK ? true : false
    );


    //will only read own Database -- add listeners to it and sync it with local redux
    const initialRead = useCallback(async (uid) => {

        let uidUSED = uid; //'CAN ENTER OTHER UID TO USE HERE'
        let isFirst = true;

        console.log('INITIAL READ WAS CALLED!', uid);

        database.ref(`USERS/${uidUSED}`).off();
        return database.ref(`USERS/${uidUSED}`).on("value", async snapshot => {

            window.firstLoad = true;
            let userData = snapshot.val();

            console.log('Initial Read and User-Data: ', userData);

            if (!snapshot.exists()) {
                console.log('User Data for this UID was undefined!');
                message.error('Could not find proper database for you. Please sign-up with different account and login');
                auth.signOut();
                return {};
            }

            if (isFirst === true) {
                console.log('Initial login recorded to Mixpanel.. ', uidUSED);

                isFirst = false;
            }

            dispatch(updateInLocalDatabase({ objToAdd: userData }));
        });

    }, [dispatch]);


    useEffect(() => {
        if (isDirectionRTL) {
            document.documentElement.classList.add('rtl');
            document.documentElement.setAttribute('data-direction', 'rtl')
        } else {
            document.documentElement.classList.remove('rtl');
            document.documentElement.setAttribute('data-direction', 'ltr')
        }

        if (themeColor) {
            styleSheetLink.href = `/css/${themeColor}.min.css`;
        }
    }, [themeColor, isDirectionRTL]);

    useEffect(() => {
        if (isDarkMode) {
            localStorage.setItem('themeType', THEME_TYPE_DARK)
            dispatch(setThemeType(THEME_TYPE_LITE));
        }
        else {
            localStorage.setItem('themeType', THEME_TYPE_LITE)
            dispatch(setThemeType(THEME_TYPE_LITE));
        }

    }, [isDarkMode, dispatch])

    useEffect(() => {
        if (themeType === THEME_TYPE_DARK) {
            document.body.classList.add('dark-theme');
            styleSheetLink.href = "/css/style.css";
        }
        else if (document.body.classList.contains('dark-theme')) {
            console.log('Remove dark mode css', themeType)
            document.body.classList.remove('dark-theme');
            styleSheetLink.href = "/css/style.css";
        }
    }, [themeType]);


    useEffect(() => {
        if (initURL === '') {
            dispatch(setInitUrl(location.pathname));
        }
        const params = new URLSearchParams(location.search);

        if (params.has("theme")) {
            dispatch(setThemeType(params.get('theme')));
        }
        if (params.has("nav-style")) {
            dispatch(onNavStyleChange(params.get('nav-style')));
        }
        if (params.has("layout-type")) {
            dispatch(onLayoutTypeChange(params.get('layout-type')));
        }
    }, [location.search, dispatch, initURL, location.pathname]);

    useEffect(() => {
        setLayoutType(layoutType);
        setNavStyle(navStyle);
    }, [layoutType, navStyle]);

    //based on the initial route of the URL the app will behave differently
    useEffect(() => {

        // //this means someone tried to visit root domain
        // if (location.pathname === '/') {
        //     console.log('Root Domain Visit Detected. Redirecting to welcome page..');
        //     history.push('/');
        // }

        //this means that an existing account already loaded with db tried to login with different account so need to re-initialize
        if (authUser && db && initURL !== '' && initURL !== '/signup') {
            console.log('INIT-APP-USEEFFECT LINE 657: ', {authUser, db, initURL});
            if (db['UID'] !== authUser) {
                initialRead(authUser)
                    .then(() => {
                        history.push(`/main/dashboard`);
                    })
            }
        }

        //this means user entered from fresh account with sign-up
        else if (authUser && !db && initURL === '/signup') {
            console.log('INIT-APP-USEEFFECT LINE 675: ', {authUser, db, initURL});
            initialRead(authUser)
                .then(() => {
                    history.push(`/main/dashboard`);
                })
        }

        //this means that completely fresh user login with no previous login attempts
        //or same account login again
        else if (authUser && !db && initURL !== '' && initURL !== '/signup') {
            console.log('INIT-APP-USEEFFECT LINE 692: ', {authUser, db, initURL});
            initialRead(authUser)
                .then(() => {
                    if (db) {
                        history.push(initURL);
                        console.log('APP INDEX JS REDIRECT TO: ', initURL);
                    }
                })
        }

        

    }, [authUser, initURL, location, history, db, dispatch, initialRead]);

    const currentAppLocale = AppLocale[locale.locale];

    return (
        <ConfigProvider locale={currentAppLocale.antd} direction={isDirectionRTL ? 'rtl' : 'ltr'}>
            <IntlProvider

                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}>

                <Switch>
                    <Route exact path='/' component={HomePage} />
                    <Route exact path='/welcome' component={HomePage} />
                    <Route exact path='/signin' component={SignIn} />
                    <Route exact path='/login' component={SignIn} />
                    <Route exact path='/signup' component={SignUp} />
                    <Route exact path='/force-logout' component={ForceLogout} />


                    <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location}
                        component={MainApp} />
                </Switch>
            </IntlProvider>
        </ConfigProvider>
    )
};

export default memo(App);