import { 
    CLOSE_STUDENT_PROFILE, 
    SHOW_STUDENT_PROFILE } from "../../constants/ActionTypes";
  
  
  const INIT_STATE = {
    profileInfo: undefined,
  };
  
  const StudentProfileReducer = (state = INIT_STATE, action) => {
        switch (action.type) {
        
    
        case SHOW_STUDENT_PROFILE: {
    
            return {
            ...state,
            profileInfo: action.payload,
            };
        }
    

        case CLOSE_STUDENT_PROFILE: {
            console.log('action.payload', action.payload)
            return {
                ...state,
                profileInfo: action.payload,
            };
        }
    

    
        default:
            return state;
        }
  };
  
  export default StudentProfileReducer;