import React from 'react';
import { Row, Col, Button } from 'antd';




const servicesRow1 = [
    { icon: <img className='gx-service-icon' src="/assets/images/icon1.png" loading="lazy" alt="Social Media Design" />, title: 'Social Media Design' },
    { icon: <img className='gx-service-icon' src="/assets/images/icon2.png" loading="lazy" alt="Brand Identity" />, title: 'Brand Identity' },
    { icon: <img className='gx-service-icon' src="/assets/images/icon3.png" loading="lazy" alt="Advertising" />, title: 'Advertising' },
];

const servicesRow2 = [
    { icon: <img className='gx-service-icon' src="/assets/images/icon4.png" loading="lazy" alt="Printing Design" />, title: 'Printing Design' },
    { icon: <img src="/assets/images/icon5.png" loading="lazy" alt="E-commerce Ads" />, title: 'E-commerce Ads' },
];

const servicesRow3 = [
    { icon: <img className='gx-service-icon' src="/assets/images/icon6.png" loading="lazy" alt="Motion Graphics" />, title: 'Motion Graphics' },
    { icon: <img className='gx-service-icon' src="/assets/images/icon7.png" loading="lazy" alt="UI Design" />, title: 'UI Design' },
    { icon: <img className='gx-service-icon' src="/assets/images/icon8.png" loading="lazy" alt="Photography" />, title: 'Photography' },
];


const ServicesSection = ({ scrollToContact }) => {
    return (
        <div className="gx-service-area gx-section gx-bg-dark">
            <div className="gx-container">
                <div className="gx-service-desktop">
                    <Row justify="space-between" align="middle" gutter={[24, 24]}>
                        <Col xs={24} md={12} lg={11} xl={11} xxl={10} className="gx-service-text">
                            <span className='gx-d-block gx-mb-2 gx-service-sub-heading' style={{ color: '#fff' }}>My services for</span>
                            <h1 className='gx-mb-2 gx-service-title'>YOUR BUSINESS DEVELOPMENT</h1>

                            <Button onClick={scrollToContact} className="gx-home-btn gx-btn-info" style={{ marginTop: '20px', fontSize: '20px' }}>
                                Let's start a project together
                            </Button>
                        </Col>
                        <Col xs={24} md={12} lg={13} xl={13} xxl={14}>
                            {/* First Row - 3 Items */}
                            <Row gutter={[14, 14]} justify="center">
                                {servicesRow1.map((service, index) => (
                                    <Col style={{ display: 'flex', justifyContent: 'center' }} xs={12} sm={8} md={8} key={index}>
                                        <div
                                            className="gx-service-card"

                                        >
                                            <div style={{ marginBottom: '10px' }}>{service.icon}</div>
                                            <p className="gx-service-content-title gx-mb-0">{service.title}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            {/* Second Row - 2 Items */}
                            <Row gutter={[14, 14]} justify="center" style={{ marginTop: '16px' }}>
                                {servicesRow2.map((service, index) => (
                                    <Col style={{ display: 'flex', justifyContent: 'center' }} xs={12} sm={8} md={8}>
                                        <div
                                            className="gx-service-card"

                                        >
                                            <div style={{ marginBottom: '10px' }}>{service.icon}</div>
                                            <p className="gx-service-content-title gx-mb-0">{service.title}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            {/* Third Row - 3 Items */}
                            <Row gutter={[14, 14]} justify="center" style={{ marginTop: '16px' }}>
                                {servicesRow3.map((service, index) => (
                                    <Col style={{ display: 'flex', justifyContent: 'center' }} xs={12} sm={8} md={8}>
                                        <div
                                            className="gx-service-card"

                                        >
                                            <div style={{ marginBottom: '10px' }}>{service.icon}</div>
                                            <p className="gx-service-content-title gx-mb-0">{service.title}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className="gx-service-mobile">
                    <Row justify="space-between" align="middle" gutter={[40, 40]}>
                        <Col xs={24} md={12} lg={12} xl={10} xxl={10} className="gx-service-text">
                            <span className='gx-d-block gx-mb-2 gx-service-sub-heading' style={{ color: '#fff' }}>My services for</span>
                            <h1 className='gx-mb-2 gx-service-title'>YOUR BUSINESS DEVELOPMENT</h1>

                            <Button onClick={scrollToContact} className="gx-home-btn gx-btn-info" style={{ marginTop: '20px', fontSize: '20px' }}>
                                Let's start a project together
                            </Button>
                        </Col>
                        <Col xs={24} md={12} lg={12} xl={14} xxl={14}>
                            {/* First Row - 3 Items */}
                            <Row gutter={[14, 14]} justify="center">
                                {[...servicesRow1, ...servicesRow2, ...servicesRow3].map((service, index) => (
                                    <Col className='gx-mb-2' style={{ display: 'flex', justifyContent: 'center' }} xs={12} sm={8} md={8} key={index}>
                                        <div
                                            className="gx-service-card"

                                        >
                                            <div style={{ marginBottom: '10px' }}>{service.icon}</div>
                                            <p className="gx-service-content-title gx-mb-0">{service.title}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>


                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default ServicesSection;
